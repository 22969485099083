import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import Contact from "./style"
import Recaptcha from "react-google-recaptcha"

export default function ModalCard({
  isModalOpen,
  setIsModalOpen,
  title,
  ...rest
}) {
  const [subject, setSubject] = useState()
  const handleClose = () => {
    setIsModalOpen(false)
  }

  const recaptchaRef = React.createRef()
  const [buttonDisabled, setButtonDisabled] = React.useState(true)
  const RECAPTCHA_KEY = "6LdrxbImAAAAABbG6LzABvhxy83Ai-HOHZz2kx7c"
  const [phoneNumber, setPhoneNumber] = React.useState("")
  const handlePhoneNumber = event => {
    const input = event.target.value
    const numericInput = input.replace(/\D/g, "") // Remove non-numeric characters

    setPhoneNumber(numericInput)
  }

  return (
    <>
      <Contact>
        <Modal
          show={isModalOpen}
          onHide={handleClose}
          backdrop="false"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Talk to Expert
              <div className="fs-6 fw-light">
                15 Minutes Free Consultation Call
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col className="col-xl-12 col-lg-12">
              <Contact.From className="p-2">
                <form
                  name="talk_to_expert"
                  method="POST"
                  data-netlify-recaptcha="true"
                  data-netlify="true"
                >
                  <input
                    type="hidden"
                    name="form-name"
                    value="talk_to_expert"
                  />
                  <input type="hidden" name="subject" value={subject} />

                  <Row>
                    <Col xs="12" className="col-lg-6 mb-4">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          placeholder="Name"
                          id="name"
                          type="text"
                          name="name"
                          minLength="4"
                          required="required"
                        />
                        <label htmlFor="name">Name*</label>
                      </div>
                    </Col>
                    <Col xs="12" className="col-lg-6 mb-4">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          id="email"
                          name="email"
                          required="required"
                          onChange={e =>
                            setSubject(
                              "Azk AI request quote submission by " +
                                e.target.value
                            )
                          }
                        />
                        <label htmlFor="email">Your Email*</label>
                      </div>
                    </Col>
                    <Col xs="12" className="col-lg-6 mb-4">
                      <div className="form-floating">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Phone Number"
                          id="phone-number"
                          name="phone"
                          required="required"
                          value={phoneNumber}
                          onChange={handlePhoneNumber}
                        />
                        <label htmlFor="floatinginput2">Phone Number*</label>
                      </div>
                    </Col>
                    {/* {!title && (
                      <Col xs="12" className="col-lg-6 mb-4">
                        <div className="form-floating">
                          <label style={{ fontSize: "12px" }}>Category*</label>
                          <select
                            class="form-control"
                            id="category"
                            required="required"
                            name="category"
                          >
                            <option
                              value=""
                              style={{ color: "rgba(#262729, 0.5)" }}
                            >
                              Please select category
                            </option>
                            <option value="Small Business Website">
                              Small Business Website
                            </option>
                            <option value="Slack Apps">Slack Apps</option>
                            <option value="Shopify Store">Shopify Store</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </Col>
                    )} */}

                    <Col xs="12" className="col-lg-6">
                      <div className="form-floating">
                        {title ? (
                          <>
                            <input
                              type="text"
                              readOnly
                              className="form-control"
                              name="category"
                              required="required"
                              value={title}
                            />
                            <label htmlFor="floatinginput2">Category*</label>
                          </>
                        ) : (
                          <>
                            <select
                              class="form-control"
                              id="category"
                              required="required"
                              name="category"
                              style={{ paddingLeft: "20px" }}
                            >
                              <option
                                value=""
                                style={{ color: "rgba(#262729, 0.5)" }}
                              >
                                Please select category
                              </option>
                              <option value="Small Business Website">
                                Small Business Website
                              </option>
                              <option value="Slack Apps">Slack Apps</option>
                              <option value="Shopify Store">
                                Shopify Store
                              </option>
                              <option value="Other">Other</option>
                            </select>
                            <label style={{ fontSize: "12px" }}>
                              Category*
                            </label>
                          </>
                        )}
                      </div>
                    </Col>

                    {/* {title ? (
                      <Col xs="12" className="col-lg-6">
                        <div className="form-floating">
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            name="category"
                            required="required"
                            value={title}
                          />
                          <label htmlFor="floatinginput2">Category*</label>
                        </div>
                      </Col>
                    ) : null} */}

                    <Col xs="12" className="col-lg-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Leave a message here"
                          id="message"
                          name="message"
                        />
                        <label htmlFor="message">Your Message Here</label>
                      </div>
                    </Col>
                    <Col xs="12" className="col-lg-12 mt-3">
                      <Recaptcha
                        ref={recaptchaRef}
                        sitekey={RECAPTCHA_KEY}
                        size="normal"
                        id="recaptcha-google"
                        onChange={() => setButtonDisabled(false)}
                      />
                    </Col>
                    {/* <Col xs="12" className="col-lg-12">
                      <div data-netlify-recaptcha="true"></div>
                    </Col> */}
                    <Col xs="12" className="col-lg-12">
                      <Row className="align-items-center mt-3">
                        <Col
                          xs="12"
                          className="col-md-12 col-lg-12 col-xl-12 text-end pt-3"
                        >
                          <Contact.Button disabled={buttonDisabled}>
                            Send Message
                          </Contact.Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </form>
              </Contact.From>
            </Col>
          </Modal.Body>
        </Modal>
      </Contact>
    </>
  )
}
