import React from "react"
import Modal from "react-bootstrap/Modal"
import { Remarkable } from "remarkable"
import Card from "./style"

var md = new Remarkable()
export default function ModalCard({ handleClose, ...rest }) {
  return (
    <>
      <Modal
        show={rest.show}
        onHide={handleClose}
        backdrop="false"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{
        //   width: "750px",
        //   maxHeight: "500px",
        // }}
        // style={{
        //   width: "500px",
        //   maxHeight: "500px",
        //   right: "2%",
        //   top: "10%",
        //   left: "auto",
        //   background: "none",
        // }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{rest.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p dangerouslySetInnerHTML={{ __html: md.render(rest.text) }}></p>
          <br />
          <div>
            {/* Main image */}
            <Card.Image src={rest.image} className="mb-3 ms-3" />

            {/* Other Gallery images */}
            {rest.gallery && rest.gallery.length > 0
              ? rest.gallery.map((item, index) => (
                  <Card.Image
                    src={item.image}
                    key={index}
                    className="mb-3 ms-3"
                  />
                ))
              : null}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
