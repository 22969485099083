import React from "react"
import { PageWrapper } from "~components/Core"
import HeroSection from "~sections/agency/Hero"
// import HeroSection from "~sections/digital/Hero"
import ServicesSection from "~sections/digital/Services"
import AboutSection from "~sections/digital/About"
import ContentSectionOne from "~sections/digital/ContentOne"
import ContentSectionTwo from "~sections/digital/ContentTwo"
import TeamSection from "~sections/digital/Team"
import PortfolioSection from "~sections/digital/Portfolio"
import PromoSection from "~sections/digital/Promo"
import HeaderButton from "~sections/digital/Header"
import FooterSix from "~sections/digital/FooterSix"
import RequestQuote from "~sections/digital/RequestQuote"
import { StaticQuery, graphql } from "gatsby"

const header = {
  headerClasses:
    "site-header site-header--menu-end dark-header site-header--sticky",
  containerFluid: false,
  // buttonBlock: (
  //   <HeaderButton className="d-none d-sm-flex" children="Request a Quote" />
  // ),
  buttonBlock: <RequestQuote />,
  darkLogo: false,
}

const Index = () => (
  <StaticQuery
    query={digitalPageData}
    render={data => {
      return (
        <PageWrapper headerConfig={header}>
          <HeroSection mainSection={data.mainSection} />
          <ServicesSection whatWeDo={data.whatWeDo} />
          <AboutSection whyChooseUs={data.whyChooseUs} />
          {/* <ContentSectionOne whoWeAre={data.whoWeAre} /> */}
          <TeamSection whoWeAre={data.whoWeAre} />
          <ContentSectionTwo ourMission={data.ourMission} />
          {/* <TeamSection/> */}
          <PortfolioSection caseStudy={data.caseStudy} />
          <PromoSection />
          <FooterSix />
          {/* <form name="talk_to_expert" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="talk_to_expert" />
            <div>
              <label>
                Your Name: <input type="text" name="name" required />
              </label>
            </div>
            <div>
              <label>
                Your Email: <input type="email" name="email" required />
              </label>
            </div>
            <div>
              <label>
                Your Phone Number: <input type="tel" name="phone" />
              </label>
            </div>
            <div>
              <label>
                Your Email: <input type="text" name="category" required />
              </label>
            </div>

            <div>
              <label>
                Your Message: <textarea name="message" required></textarea>
              </label>
            </div>
            <div>
              <button type="submit">Submit</button>
            </div>
          </form> */}
        </PageWrapper>
      )
    }}
  />
)

export const digitalPageData = graphql`
  {
    whoWeAre: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "posts/whoWeAre/" } }
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            title
            linkedin_link
            photo
          }
          excerpt
        }
      }
    }
    ourMission: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "posts/ourMission/" } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            image1
            image2
          }
          html
        }
      }
    }

    whyChooseUs: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "posts/whyUseUs/" } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            video
            projects_done
            conservation_rate
            return_happy_customer
          }
        }
      }
    }

    caseStudy: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "posts/caseStudy/" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            image2
            image1
            description
            Case_Studies {
              title
              image
              description
              short_description
              Case_Study_Gallery {
                image
              }
            }
          }
        }
      }
    }

    whatWeDo: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "posts/ourServices/" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            ourservices {
              title
              description
              image
            }
          }
        }
      }
    }

    mainSection: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "posts/mainSection/" } }
    ) {
      edges {
        node {
          frontmatter {
            titles {
              title
            }
          }
        }
      }
    }

    contactDetails: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "posts/contactDetail/" } }
    ) {
      edges {
        node {
          frontmatter {
            address
            phone
            email
            slogan
          }
        }
      }
    }
  }
`

export default Index

// export default function Digital() {
//   return (
//     <PageWrapper headerConfig={header}>
//       <HeroSection />
//       <ServicesSection />
//       <AboutSection />
//       <ContentSectionOne />
//       <ContentSectionTwo />
//       {/* <TeamSection /> */}
//       <PortfolioSection />
//       <PromoSection />
//       <FooterSix />
//     </PageWrapper>
//   )
// }

// import React from "react"
// import { PageWrapper } from "~components/Core"
// import HeroSection from "~sections/marketing/Hero"
// import ServiceSection from "~sections/marketing/Service"
// import FeatureSection from "~sections/marketing/Features"
// import ContentSectionOne from "~sections/marketing/ContentOne"
// import ContentSectionTwo from "~sections/marketing/ContentTwo"
// import TestimonialSection from "~sections/marketing/Testimonial"
// import CounterSection from "~sections/marketing/Counter"
// import CtaSection from "~sections/marketing/Cta"
// import FooterOne from "~sections/marketing/FooterOne"
// import HeaderButton from "~sections/marketing/Header"
// const header = {
//   headerClasses:
//     "site-header site-header--menu-start light-header site-header--sticky",
//   containerFluid: true,
//   // customLogo: Images.HeaderLogo,
//   buttonBlock: (
//     <HeaderButton
//       className="ms-auto d-none d-xs-inline-flex"
//       btnOneText="Login"
//       btnTwoText="Get started"
//       mr="15px"
//       mrLG="0"
//     />
//   ),
// }

// export default function Marketing() {
//   return (
//     <PageWrapper headerConfig={header}>
//       <div>AAA</div>
//       <HeroSection />
//       <ServiceSection />
//       <FeatureSection />
//       <ContentSectionOne />
//       <ContentSectionTwo />
//       <TestimonialSection />
//       <CounterSection />
//       <CtaSection />
//       <FooterOne />
//     </PageWrapper>
//   )
// }

// mainSection: allMarkdownRemark(
//       filter: { fileAbsolutePath: { regex: "posts/mainSection/" } }
//     ) {
//       edges {
//         node {
//           frontmatter {
//             // title
//             // text
//             titles {
//               title
//             }
//           }
//         }
//       }
//     }
