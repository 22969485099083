import React, { useState } from "react"
import Card from "./style.js"
import TalkToExpert from "~components/TalkToExpert"
export default function ServiceCard({ title, text, image, ...rest }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }
  return (
    <Card backgroundColor="#F3F4F6" {...rest}>
      <Card.Icon>
        <img src={image} alt="Service" />
      </Card.Icon>
      <Card.Body>
        <Card.Title as="h3" fontColor="#262729">
          {title}
        </Card.Title>
        <Card.Text fontColor="#262729">{text}</Card.Text>
        <div className="text-center">
          <Card.Button fontColor="#262729" title={title} onClick={openModal}>
            Talk to an Expert
          </Card.Button>
        </div>

        {isModalOpen && (
          <TalkToExpert
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            title={title}
          />
        )}
      </Card.Body>
    </Card>
  )
}
