import styled from "styled-components/macro"
import { Box, Heading, Button, Paragraph } from "~styled"

const Card = styled(Box)`
  // padding-left: 45px;
  // padding-right: 45px;
  // padding-top: 40px;
  // padding-bottom: 45px;
  border-radius: 15px;
  min-height: 400px;
  overflow: hidden;

  @media (max-width: 991px) {
    // padding-left: 25px;
    // padding-right: 25px;
    // padding-top: 30px;
    // padding-bottom: 30px;
  }

  @media (max-width: 768px) {
    border-radius: 15px;
    // padding-left: 35px;
    // padding-right: 30px;
    // padding-top: 30px;
    // padding-bottom: 30px;
    min-height: max-content;
  }

  @media (max-width: 575px) {
    // display: flex;
    align-items: center;
    // padding-left: 45px;
    // padding-right: 45px;
    // padding-top: 40px;
    // padding-bottom: 45px;
    min-height: max-content;
  }

  &:hover {
    box-shadow: -3px 15px 30px rgb(0 0 0 / 12%);
    cursor: pointer;
  }
`

Card.Title = styled(Heading)`
  font-size: 21px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 30px;
  text-align: center;
`
Card.Text = styled(Paragraph)`
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  opacity: 0.7;
  min-height: 160px;
`
// Card.Body = styled(Box)``
// Card.Icon = styled(Box)`
//   margin-right: 30px;
// `

Card.Button = styled(Button)`
  color: #fff;
  background-color: rgb(253, 52, 110);
  border-color: rgb(253, 52, 110);
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 500px;
  text-transform: capitalize;
  &:hover {
    color: #fff;
    opacity: 0.8;
  }
`

Card.Body = styled(Box)`
  margin-bottom: 0px;
  padding-left: 45px;
  padding-right: 45px;
  padding-top: 40px;
  padding-bottom: 45px;

  @media (max-width: 991px) {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media (max-width: 768px) {
    padding-left: 35px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media (max-width: 575px) {
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 40px;
    padding-bottom: 45px;
  }
`

Card.Icon = styled(Box)`
  // margin-bottom: 15px;
  text-align: center;

  img {
    height: auto;
    width: 100%;
  }
`

export default Card
