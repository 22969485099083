import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Video from "~components/VideoModal"
import { StaticImage as Img } from "gatsby-plugin-image"
import CounterBlock from "./Components/CounterBlock"
import About from "./style"

export default function AboutSection({ ...rest }) {
  const data = rest.whyChooseUs.edges[0].node.frontmatter

  var idOfVideo = data.video.split("v=")

  return (
    <About backgroundColor="#F3F4F6" {...rest} id="why-choose-us">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col
            xs="12"
            className="col-xl-6 col-lg-7 col-md-10 order-2 order-xl-1"
          >
            <About.Box mrXL="50px" mt="30px" mtXL="0" pb="40px" pbLG="0">
              <About.ContentTextBlock className="text-center text-xl-end">
                <About.Subtitle as="h6" fontColor="#fd346e">
                  Why Choose Us
                </About.Subtitle>
                <About.Title as="h2" pb="35px" mb="40px">
                  {data.title}
                </About.Title>
                <About.Text>{data.description}</About.Text>
              </About.ContentTextBlock>
              <CounterBlock mt="50px" data={data} />
            </About.Box>
          </Col>
          <Col
            xs="12"
            className="col-xl-6 col-lg-6 col-md-8 col-sm-9 order-1 order-xl-2"
          >
            <About.Image>
              <Img
                className="w-100"
                src="../../../assets/image/home-digital-agency/about-videos-img.png"
                alt="content"
                layout="fullWidth"
                placeholder="blurred"
              />
              <Video id={idOfVideo[1]} className="video-btn">
                <i className="fa fa-play" />
              </Video>
            </About.Image>
          </Col>
        </Row>
      </Container>
    </About>
  )
}
