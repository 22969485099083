// import styled from "styled-components/macro"
import styled from "styled-components"
import { Box } from "~styled"

const Card = styled(Box)``

Card.Image = styled.img`
  height: 230px;
  width: auto;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  max-width: -webkit-fill-available;
`

export default Card
