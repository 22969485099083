import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"
import Video from "~components/VideoModal"
import { Images } from "~data"
import Hero from "./style"
import "./style.css"
import Typewriter from "typewriter-effect"
import TalkToExpert from "~components/TalkToExpert"

export default function HeroSection({ mainSection }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }

  const data = mainSection.edges[0].node.frontmatter
  const [showButton, setShowButton] = useState(false)

  const typeWriterEffect = () => {
    const collection = document.getElementsByClassName("Typewriter__wrapper")
    var button = document.getElementById("read-more-btn")
    if (
      (collection[0].innerHTML.length == data.titles[0].title.length &&
        collection[0].innerHTML.substring(0, 8) ==
          data.titles[0].title.substring(0, 8)) ||
      (collection[0].innerHTML.length == data.titles[1].title.length &&
        collection[0].innerHTML.substring(0, 8) ==
          data.titles[1].title.substring(0, 8)) ||
      (collection[0].innerHTML.length == data.titles[2].title.length &&
        collection[0].innerHTML.substring(0, 8) ==
          data.titles[2].title.substring(0, 8))
    ) {
      setShowButton(true)
      // button.classList.remove("fade-out-read-more-btn")
      // button.classList.remove("d-none")
      // button.classList.add("fade-in-read-more-btn")
      // $("#read-more-btn").fadeIn(3000)
    } else {
      // button.classList.remove("fade-in-read-more-btn")
      // button.classList.add("fade-out-read-more-btn")
      // button.classList.add("d-none")
      setShowButton(false)
    }
  }

  useEffect(() => {
    const timerId = setInterval(typeWriterEffect, 100)
    return function cleanup() {
      clearInterval(timerId)
    }
  }, [100])

  return (
    <>
      <Hero
        backgroundImage={Images.agency.heroImg}
        backgroundOverlay={Images.agency.heroImgOverlay}
      >
        <Container>
          <Row className="align-items-center justify-content-center justify-content-md-start">
            {/* Welcome content Area */}
            <Col className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-xs-12 offset-xxl-2 offset-xl-2 offset-lg-2 offset-md-2">
              {/* <Col className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 "> */}
              <Hero.Content
                mb="40px"
                mbMD="40px"
                mt="30px"
                mtLG="0"
                className="text-center"
              >
                {/* <Hero.Title
                  as="p"
                >
                  {para}
                </Hero.Title> */}
                {/* <Hero.Title as="p"> */}
                <Typewriter
                  options={{
                    strings: [
                      data.titles[0].title,
                      data.titles[1].title,
                      data.titles[2].title,
                    ],
                    autoStart: true,
                    loop: true,
                    cursor: "|",
                    delay: 45,
                    pauseFor: 1500,
                    deleteSpeed: 20,
                  }}
                />
                {/* </Hero.Title> */}
                {showButton ? (
                  <Hero.Button
                    className="text-center"
                    id="read-more-btn"
                    onClick={openModal}
                  >
                    Talk to an Expert
                  </Hero.Button>
                ) : null}

                {isModalOpen && (
                  <TalkToExpert
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                  />
                )}
              </Hero.Content>
            </Col>
            {/*/ .Welcome Content Area */}
          </Row>
        </Container>
      </Hero>
    </>
  )
}
