import React from "react"
import Card from "./style"
export default function TeamCard({ image, userName, userPosition, ...rest }) {
  return (
    <>
      <Card {...rest}>
        <img src={image} alt="" />
        <Card.InnerBlock>
          <div>
            <Card.Title fontColor="#fff">{userName}</Card.Title>
            <Card.Text className="card__user-media__cat" fontColor="#fff">
              {userPosition}
            </Card.Text>
            <Card.Social>
              <Card.SocialItem
                as="a"
                href={rest.linkedln}
                tabIndex={0}
                target="_blank"
              >
                Linked <i className="fab fa-linkedin" />
              </Card.SocialItem>
            </Card.Social>
          </div>
        </Card.InnerBlock>
      </Card>
    </>
  )
}
