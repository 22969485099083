import React, { useState } from "react"
import { Link } from "~components"
import Card from "./style"
import ModalCard from "../Modal"

export default function PortfolioCard({
  to,
  image,
  title,
  text,
  shortDescription,
  ...rest
}) {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <>
      <Card
        as={Link}
        to={to ? to : "#case-studies"}
        {...rest}
        id={`${rest.id}-btn`}
        onClick={handleShow}
      >
        <img className="w-100" src={image} alt="portfolio" />
        <Card.InnerBlock backgroundColor="#fd346e">
          <Card.InnerIcon fontColor="#fff">
            <i className="fas fa-external-link-square-alt" />
          </Card.InnerIcon>
          <Card.Content>
            <Card.Title as="h3" fontColor="#fff">
              {title}
            </Card.Title>
            <Card.Text mt="15px" fontColor="#fff">
              {shortDescription}
            </Card.Text>
          </Card.Content>
        </Card.InnerBlock>
      </Card>

      {/* Right Modal */}
      {show && (
        <ModalCard
          image={image}
          title={title}
          text={text}
          gallery={rest.gallery}
          mt="25px"
          show={show}
          handleClose={handleClose}
        />
      )}
    </>
  )
}
