import styled from "styled-components/macro"
import { Box, Heading, Button, Paragraph } from "~styled"

const Card = styled(Box).attrs({ className: "team-card" })`
  position: relative;

  border-radius: 15px;
  // overflow: hidden;
  border: none;
  cursor: all-scroll;
  padding: 20px;
  box-shadow: 0 8px 45px rgb(0 0 0 / 30%);

  img {
    height: 200px;
    width: 200px;
    margin: auto;
    border-radius: 50%;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: 0 8px 45px rgb(0 0 0 / 60%);
  }

  .card-innerblock {
    // position: absolute;
    // left: 0;
    // bottom: 0;
    // transform: translateY(100%);
  }
  &:hover {
    .card-innerblock {
      // transform: translateY(0);
    }
  }
`
Card.InnerBlock = styled(Box).attrs({ className: "card-innerblock" })`
  // background: linear-gradient(
  //   360deg,
  //   #fd346e 0%,
  //   rgba(253, 52, 110, 0.69) 38.45%,
  //   rgba(253, 52, 110, 0) 79.35%,
  //   rgba(253, 52, 110, 0) 111.11%
  // );

  width: 100%;
  height: 50%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  transition: 0.5s;
  > div {
    // position: absolute;
    // left: 0;
    // bottom: 0;
    // margin-left: 45px;
    text-align: center;
    margin-bottom: 15px;
  }
`

Card.Title = styled(Heading)`
  font-size: 21px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  color: var(--bs-white);
  margin-bottom: 0;
  color: black;
  margin-top: 21px;
`
Card.Text = styled(Paragraph)`
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 22px;
  margin-bottom: 0;
  opacity: 0.7;
  color: black;
`
Card.Social = styled(Box)`
  margin-top: 5px;
  color: black;
  > a:hover {
    opacity: 0.9;
    color: white;
    cursor: pointer;
  }
`
Card.SocialItem = styled(Box)`
  color: #fff;
  padding-right: 20px;
  transition-duration: 0.4s;
  // color: black;
  background: #0077b5;
  color: white;
  padding: 5px 15px;
  /* width: 50%; */
  border-radius: 500px;
  text-align: center;
  &:hover {
    opacity: 0.85;
  }
`

export default Card
